<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path fill="currentColor"
            d="M12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51q.709-1.643 1.924-2.859T8.49 3.709T12 3q1.296 0 2.501.362t2.293 1.04q.202.123.267.33q.064.209-.07.385q-.135.177-.355.21t-.409-.09q-.954-.606-2.028-.921T12 4Q8.675 4 6.337 6.338T4 12t2.338 5.663T12 20t5.663-2.337T20 12q0-.38-.032-.737q-.031-.357-.114-.73q-.05-.214.057-.41t.323-.242q.205-.048.372.075t.217.33q.089.408.133.833T21 12q0 1.868-.708 3.51t-1.924 2.858t-2.856 1.923t-3.509.709m-1.441-6.508l9.377-9.383q.14-.14.34-.152t.371.157q.156.155.156.35t-.16.353l-9.519 9.525q-.243.242-.566.242t-.565-.242l-2.638-2.638q-.14-.14-.15-.345q-.01-.203.15-.363t.354-.16t.354.16z">
        </path>
    </svg>
</template>

<script lang="ts">
export default {
    name: 'TaskDoneIcon'
}
</script>